<template>
  <div class="about">
    <h1>Logs</h1>

    <a href="#" @click.prevent="pushLogs()" class="btn btn-hero-info">
        <i v-if="onpushlog" class="fa fa-spinner fa-spin"></i>
        Envoyer les logs
    </a>

    <p>Token : {{getToken}}
      <a href="#" class="btn btn-hero-danger" @click.prevent="logout">X</a>
      </p>

<br/>
<br/>

    <p>Nb inter à synchro : {{tosyncsInter.length}}</p>
    
    <h2>Photos</h2>

      <a href="#" @click.prevent="pushAllPictures()" class="btn btn-hero-info">Push pictures</a>
    <ul>
      <li v-for="picture in getAllPictures()">
              {{picture.uid}} | {{picture.name}} | {{picture.sync}} <a href="#" class="btn btn-sm btn-danger" @click.prevent="deletepicture(picture)">X</a>
              <a href="#"  class="btn ml-2 btn-sm btn-success" @click.prevent="syncOnePicture(picture)"><i class="fa fa-save"></i></a>
      </li>
    </ul>
    <br>
    <h2>Inter</h2>
    <a href="#" @click.prevent="refreshevents()" class="btn btn-hero-info">Reset events</a>
    <a href="#" @click.prevent="resetinter()" class="btn btn-hero-info">Reset inter</a>
    <a href="#" @click.prevent="pushAllIntervention()" class="btn btn-hero-success">Push inter</a>
    <ul>
      <li v-for="inter in getAllInter">
          {{inter}} <a href="#" class="btn btn-hero-danger" @click.prevent="deleteInter(inter.uid)">X</a>
      </li>
    </ul>
    
    <br>
    <a href="#" @click.prevent="refreshconfig()" class="btn btn-hero-success">Refresh Config</a>

    <br>
    <br>
    <textarea cols="50" rows="5">{{getRegisterToken}}</textarea>
    <a href="#" @click.prevent="resetRegisterToken()" class="btn btn-hero-success">Reset Token Notitfication</a>


  </div>
</template>

<script>

  import { mapActions, mapGetters } from 'vuex'
  import version from '@/services/version';
  import api from '@/services/api';
  import { Toast } from 'vuex-toast'
  import { ADD_TOAST_MESSAGE } from 'vuex-toast'
  import TransitionPage from '@/components/TransitionPage'


  export default {
    name: 'logs',
    components: {
      TransitionPage, Toast
    },
    data: function() {
      return {
        onpushlog: false
      }
    },
    methods: {
      ...mapActions({
        addToast: ADD_TOAST_MESSAGE
      }),
      ...mapActions('events', {'refreshevents': 'refresh'}),
      ...mapActions('interventions', {'resetinter': 'reset', 'pushAllIntervention': 'pushAll', 'deleteInter': 'delete'}),
      ...mapActions('pictures', {'pushAllPictures': 'sync', 'deletepicture': 'delete', 'syncOnePicture':'syncOne'}),
      ...mapActions('login', ['logout']),
      ...mapActions('config', { 'refreshconfig': 'refresh', 'resetRegisterToken':'resetRegisterToken'}),

      pushLogs: async function () {

        const params = {
          token: this.getToken,
          nbinter: this.tosyncsInter.length,
          pictures: this.getAllPictures(),
          inters: this.getAllInter,
          events: this.getAllEvents(),
          registertoken: this.getRegisterToken,
          version: version.version
        };

        try {
          this.onpushlog = true;
          let call = await  api.post({url: 'logs', params: JSON.stringify(params)});
          this.addToast({
            text: 'Logs envoyé au serveur',
            type: 'success',
            dismissAfter: 10000
          });
          this.onpushlog = false;

        } catch (err) {
          this.addToast({
            text: 'error pushlog : ' + err,
            type: 'danger',
            dismissAfter: 10000
          });
          this.onpushlog = false;
          console.log('error pushlog', err);
        }

      }
    },
    computed: {
      // mix the getters into computed with object spread operator
      ...mapGetters('login',[
        'getToken',
      ]),
      ...mapGetters('interventions',{'getAllInter': 'getAll', 'tosyncsInter': 'tosyncs'}),
      ...mapGetters('events',{'getAllEvents': 'getAll'}),
      ...mapGetters('pictures', {'getAllPictures':'getAll'}),
      ...mapGetters('config',['getRegisterToken'])
    },
    created(){
      this.$store.dispatch('pictures/loadall');

    },
  }
</script>
